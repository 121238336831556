/*** Fonts ***/

@font-face {
    font-family: 'als_rublregular';
    src: url('../fonts/rouble/rouble-webfont.woff2') format('woff2'),
    url('../fonts/rouble/rouble-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novabold_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novabold';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaextrabold';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaextrabold_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_ltlight';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novalight_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'proxima_novaregular_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novasemibold_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$rouble: 'als_rublregular', sans-serif;
$ProximaNovaItalic: 'proxima_novaregular_italic', sans-serif;
$ProximaNovaLight: 'proxima_nova_ltlight', sans-serif;
$ProximaNovaLightItalic: 'proxima_novalight_italic', sans-serif;
$ProximaNova: 'proxima_nova_rgregular', sans-serif;
$ProximaNovaSemibold: 'proxima_nova_ltsemibold', sans-serif;
$ProximaNovaSemiboldItalic: 'proxima_novasemibold_italic', sans-serif;
$ProximaNovaBoldItalic: 'proxima_novabold_italic', sans-serif;
$ProximaNovaBold: 'proxima_novabold', sans-serif;
$ProximaNovaExtraBold: 'proxima_novaextrabold', sans-serif;
$ProximaNovaExtraBoldItalic: 'proxima_novaextrabold_italic', sans-serif;




/*** Colors ***/

$white: #ffffff;
$black: #000;
$yellow: #fadc3d;
$otherYellow: #ffec89;
$yellowLight: #fdf3b1;
$green: #2ec827;
$darkGreen: #167312;
$darkGray: #6e6e6e;

$shadowColor: #b5b5b5;
$shadowColorLight: #e3e1e1;

$borderColor: #d3d3d3;
$borderYellowColor: #efde80;

$textColor: #000000;
$linkColor: #316c92;