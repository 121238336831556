@import '_var.scss';

@media (max-width: 1600px) {
    .list {
        &-item {
            padding-left: 40px;
        }
    }

    .set-block .title,
    .set-block .sub-title,
    .set-block .list {
        margin-bottom: 40px;
    }

    .set-block {
        .right-block {
            padding-left: 40px;
        }

        .button-wrap {
            .law {
                margin: 0 0 0 60px;
                padding: 20px;

                .title {
                    font-size: 56px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
    }

    .proprietary-software {
        .integration {
            max-width: 430px;
        }
    }
}

@media (max-width: 1441px) {
    h1, .h1 {
        font-size: 65px;
        line-height: 65px;
    }
    h2, .h2 {
        font-size: 47px;
        line-height: 47px;
    }
    h3, .h3 {
        font-size: 26px;
    }

    .btn {
        padding: 20px;
    }

    .sub-title {
        font-size: 42px;
    }

    .first-block {
        min-height: 0;
        padding-top: 250px;
    }

    .set-block .title,
    .set-block .sub-title,
    .set-block .list {
        margin-bottom: 20px;
    }
    .set-block {
        .btn {
            max-width: 300px;
        }
    }

    .set-block,
    .fines-block,
    .process-block-wrap,
    .scheme-wrap,
    .video-wrap,
    .price-wrap,
    .get-started-wrap,
    .contact-block {
        margin-top: 100px;
    }

    .condition-wrap {
        margin-top: 0;
    }

    .process-block-wrap {
        padding: 150px 0 90px;
    }

    .fines-block {
        .big-text {
            font-size: 42px;
            line-height: 42px;
        }
    }

    .functions-wrap {
        .button-wrap {
            margin-top: 60px;
        }
    }

    .scheme-wrap {
        img {
            margin-top: 60px;
        }
    }

    .get-started-block {
        margin-top: 60px;

        .phone,
        .mail .text {
            font-size: 22px;
        }

        .mail {
            .icon img {
                width: 18px;
            }
        }
    }
}

@media (max-width: 1301px) {
    h1, .h1 {
        font-size: 55px;
        line-height: 50px;
    }
    h2, .h2 {
        font-size: 40px;
        line-height: 40px;
    }
    h3, .h3 {
        font-size: 24px;
    }

    .sub-title {
        font-size: 34px;
    }

    .btn {
        font-size: 16px;
        font-family: $ProximaNovaSemibold;
        border-radius: 10px;
    }

    .input {
        height: 60px;
        padding: 0 20px;
        line-height: 60px;
        border-radius: 5px;
    }

    .list {
        .title {
            font-size: 20px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .process-block-wrap {
        padding-top: 100px;

        .sub-title {
            margin-top: 30px;
        }

        .advantages-item {
            margin-top: 10px;
        }

        .wide-image {
            margin-top: 80px;
        }
    }

    .process-list {
        margin-top: 70px;

        &-item {
            margin-top: 60px;
        }

        .title {
            font-size: 30px;
        }

        .num {
            margin-right: 20px;
            font-size: 56px;
        }

        p {
            font-size: 16px;
            line-height: 25px;
        }

        .support {
            padding: 40px;

            .title {
                font-size: 30px;
            }

            a {
                margin: 40px 0 0;
                font-size: 16px;

                .icon {
                    margin-top: -24px;
                }
                .text {
                    height: 58px;
                }
            }
        }
    }

    .fines-block {
        th, td {
            padding: 20px;
        }

        .label {
            font-size: 16px;
            line-height: 13px;
        }
        .big-text {
            font-size: 32px;
            line-height: 32px;
        }
    }

    .functions-wrap {
        margin-top: 60px;
    }

    .functions-list .title {
        margin-bottom: 0;
        font-size: 26px;
        line-height: 26px;
    }

    .video-wrap {
        .sub-title {
            margin-top: 20px;
        }

        .video {
            margin-top: 60px;
        }

        .button-wrap {
            margin: 60px 0 0;
        }
    }

    .price-wrap {
        .price-block {
            .head {
                .title {
                    flex: 2;
                    font-size: 30px;
                }
                .image {
                    flex: 1;
                }
            }

            .left-block {
                .head {
                    padding: 30px 0 0 30px;
                }
                .price-list {
                    padding: 0 30px 30px;

                    &-item {
                        padding: 20px 0;
                    }

                    .label {
                        font-size: 16px;
                    }
                    .cost {
                        font-size: 20px;
                    }
                }
            }
            .right-block {
                .head {
                    padding: 30px 0 30px 30px;
                }
                .price-list {
                    &-item {
                        padding: 20px 0;
                    }

                    .label {
                        font-size: 16px;
                    }
                    .cost {
                        font-size: 20px;
                    }
                    .small {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .set-list {
        &-item {
            margin-top: 30px;
            padding: 20px 0 20px 20px;
        }

        .image {
            padding-right: 20px;
        }

        .title {
            margin: 0;
            padding-right: 20px;
            font-size: 30px;
            line-height: 30px;
        }
        p {
            padding-right: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .contact-block {
        .wrap {
            padding: 60px;
        }

        .person-wrap {
            .label {
                font-size: 16px;
                letter-spacing: 4px;
            }
            .name {
                margin-top: 10px;
                font-size: 24px;
            }
        }

        .form {
            margin-top: 30px;
        }
    }

    .condition-wrap {
        padding: 150px 0 100px;

        p {
            margin-top: 30px;
            font-size: 25px;
        }

        .alert-text {
            margin-top: 30px;
        }

        .info-text {
            margin-top: 30px;
            font-size: 16px;
        }

        .condition-block {
            .date {
                .day {
                    margin-right: 30px;
                    font-size: 150px;
                }
                .month {
                    font-size: 50px;
                    line-height: 40px;
                }
                .year {
                    font-size: 60px;
                    line-height: 50px;
                }
            }
        }
    }
}

@media (max-width: 1281px) {
    .first-block {
        padding-top: 230px;
    }

    .set-block {
        .button-wrap {
            flex-direction: column;

            .btn {
                max-width: 365px;
            }
            .law {
                margin: 30px 0 0;
            }
        }
    }
}

@media (max-width: 1025px) {
    h1, .h1 {
        font-size: 45px;
        line-height: 40px;
    }
    h2, .h2 {
        font-size: 30px;
        line-height: 30px;
    }
    h3, .h3 {
        font-size: 18px;
    }

    .sub-title {
        font-size: 24px;
    }

    .set-block {
        flex-wrap: wrap;

        .left-block,
        .right-block {
            flex: 100%;
        }

        .right-block {
            padding: 30px 0 0;
        }
    }
    .set-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;

        &-item,
        &-item:first-child {
            flex: 50%;
            max-width: calc(50% - 20px);
            margin: 20px 0 0 20px;
        }
    }

    .proprietary-software {
        padding-top: 60px;

        &-wrap {
            margin: 60px 0 0;
        }

        &-image {
            margin-right: 0;
        }

        .integration {
            max-width: 100%;
            margin: 60px 0 0;
            padding: 0 0 0 20px;

            p {
                margin-top: 20px;
                font-size: 22px;
            }

            .software {
                margin-top: 20px;
            }
        }
    }

    .condition-wrap {
        margin-top: 60px;
        padding: 80px 0;

        .left-block {
            padding-right: 20px;
        }

        p {
            margin-top: 20px;
            font-size: 20px;
        }
    }

    .fines-block {
        th, td {
            padding: 20px 10px;
        }

        .label {
            font-size: 14px;
            line-height: 13px;
        }
        .big-text {
            font-size: 26px;
            line-height: 26px;
        }

        .list {
            p {
                font-size: 14px;
            }
        }
    }


    .process-block-wrap {
        padding: 60px 0;
        background-position-y: -30%;

        .advantages {
            margin: 10px 0 0 -20px;

            &-item {
                margin: 20px 0 0 20px;
                padding: 10px 20px;
                font-size: 14px;
            }
        }
    }

    .process-list {
        &-item {
            &:nth-child(even) {
                padding: 0 0 0 30px;
            }

            .wrap {
                padding: 0;
            }
        }

        .support {
            .title {
                font-size: 24px;
                line-height: 30px;
            }

            a {
                margin: 30px 0 0;
                font-size: 14px;

                .text {
                    height: 48px;
                }
            }
        }
    }

    .functions-list {
        &-item {
            padding-left: 30px;
        }
        .image {
            height: 70px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 20px;
            line-height: 20px;
        }

        p {
            margin-top: 10px;
            font-size: 14px;
        }
    }

    .scheme-wrap img {
        margin-top: 30px;
    }

    .price-wrap {
        .price-block {
            flex-wrap: wrap;

            .left-block {
                flex: 100%;
                padding: 0;
            }
            .right-block {
                padding: 40px 0 0;
            }

            .head {
                align-items: center;
            }
        }
    }

    .get-started-block {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;

        .item,
        .item:first-child {
            margin: 30px 30px 0;
        }

        .small {
            padding: 10px 0 0;
            text-align: center;
        }
        .phone {
            justify-content: center;
        }
        .messengers {
            margin-top: 10px;
        }
    }

    .contact-block .wrap {
        padding: 40px;
    }
}

@media (max-width: 769px) {
    .first-block {
        .left-block {
            max-width: 100%;
        }

        .list {
            max-width: 100%;
        }
    }

    .set-block,
    .fines-block,
    .process-block-wrap,
    .scheme-wrap,
    .video-wrap,
    .price-wrap,
    .get-started-wrap,
    .contact-block {
        margin-top: 60px;
    }

    .set-list {
        .title {
            font-size: 24px;
            line-height: 24px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .condition-block {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .fines-block {
        th, td {
            padding: 10px 5px;
            font-size: 12px;

            &:first-child {
                width: 15%;
            }
        }

        .list {
            p {
                font-size: 12px;
                line-height: 16px;
                text-transform: none;
            }
        }

        .type {
            .label {
                padding: 5px;
                letter-spacing: 0;
            }
        }


        .label {
            margin-bottom: 20px;
        }

        .big-text {
            font-size: 20px;
            line-height: 20px;

            .small {
                margin-bottom: 5px;
            }
        }
    }
}

@media (max-width: 561px) {
    h1, .h1 {
        font-size: 40px;
        line-height: 40px;
    }

    .sub-title {
        font-size: 20px;
    }

    .list {
        flex-direction: column;

        &-item {
            padding: 20px 0 0;

            &:first-child {
                padding: 0;
            }
        }
    }

    .set-list {
        margin-left: 0;

        &-item,
        &-item:first-child {
            flex: 100%;
            max-width: 100%;
            margin: 20px 0 0 0;
        }
    }

    .proprietary-software {
        flex-direction: column;
        padding-top: 0;

        .integration {
            margin: 40px 0 0;
            padding: 0;

            p {
                font-size: 18px;
            }
        }
    }

    .condition-wrap {
        padding: 60px 0;

        .title {
            span {
                font-size: 40px;
            }
        }

        .alert-text {
            margin-top: 20px;
            font-size: 16px;
        }
        .info-text {
            margin-top: 20px;
            font-size: 14px;
        }
    }

    .fines-block-wrap {
        overflow: auto;

        .fines-block {
            width: 800px;

            th:first-child,
            td:first-child {
                width: 20%;
            }
        }
    }

    .process-list {
        &-item {
            flex: 100%;
            margin-top: 40px;

            &:nth-child(even) {
                padding: 0;
            }

            &:first-child {
                margin: 0;
            }
        }

        .title {
            font-size: 28px;
        }
        .num {
            font-size: 50px;
        }
        p {
            margin-top: 10px;
            font-size: 14px;
            line-height: 23px;
        }

        .support {
            padding: 20px;
        }
    }

    .functions-list {
        flex-wrap: wrap;

        &-item {
            flex: 100%;
            padding: 40px 0 0;
            text-align: center;

            &:first-child {
                padding: 0;
            }
        }
    }

    .video-wrap {
        .video {
            height: 300px;
        }
    }

    .price-wrap {
        .small-title {
            margin-top: 30px;
            font-size: 18px;
        }

        .price-block {
            margin-top: 40px;
        }
    }

    .contact-block {
        .wrap {
            padding: 20px;
        }

        .person-wrap {
            .image {
                margin-right: 20px;
            }
            .label {
                font-size: 14px;
                letter-spacing: 2px;
            }
            .name {
                font-size: 20px;
            }
        }
    }
    .form {
        .col {
            flex: 100%;
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .price-wrap {
        .price-block {
            .head {
                .title {
                    font-size: 22px;
                }
            }

            .left-block  {
                .head {
                    padding: 20px 0 0 20px;
                }
                .price-list {
                    padding: 0 20px 20px;

                    .label {
                        padding-right: 20px;
                        font-size: 14px;
                    }
                    .cost {
                        font-size: 16px;
                    }
                }
            }
            .right-block {
                .head {
                    padding: 20px;
                }

                .price-list {
                    &-item {
                        padding: 15px 0;
                    }

                    .label {
                        font-size: 14px;
                    }
                    .cost {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 376px) {
    .first-block {
        padding-top: 300px;
    }
}